import React from "react"
import styles from "../common.module.scss"
import { motion } from "framer-motion"
import ProjectStatusBar from "../common/ProjectStatusBar"
import UserJourney from "./../../../images/projects/tacorrido/user-journey-2x.png"

export default function index() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key="PubSubManager"
    >
      <div className={styles.devProjectPage}>
        <header className={styles.pageHeader}>
          <h1 className={styles.pageTitle}>Tacorrido</h1>

          <ProjectStatusBar
            statusText="In progress"
            githubUrl="https://github.com/isaacd8k/tacorrido-kiosk"
          />
        </header>

        <main className={styles.mainContent}>
          {/* Specifications / requirements */}
          <section className={styles.mainSection}>
            <h2>Project Spec</h2>
            <p>
              TASK: Build a Shopify-powered, self-service kiosk for a local
              taqueria. This customer-facing app should:
            </p>
            <ul>
              <li>Allow easy ordering of standard menu items</li>
              <li>
                Allow building custom tacos with in-stock restaurant ingredients
              </li>
              <li>
                Use Shopify data for collections names, products information,
                images, and prices
              </li>
              <li>Automatic inactive session reset after idle time</li>
              <li>Interface with Shopify Checkout for completing order</li>
            </ul>
          </section>

          {/* Scope of work */}
          <section className={styles.mainSection}>
            <h2>Scope of work</h2>
            <p>
              An existing Shopify store backend will be connected to the web app
              build process. I will leverage the headless Shopify Hydrogen
              framework, using React 18's Streaming SSR features for an
              experience that is performant, efficient, and resilient.
              <br />
              <br />
              <h6>Tech stack:</h6>
              <ul>
                <li>
                  Shopify Headless API for our e-commerce backend (hosted by
                  Shopify)
                </li>
                <li>Shopify Hydrogen framework (deployed on cloud host)</li>
                <li>React 18</li>
              </ul>
            </p>
            {/* What will I be building? What will it include/not include
          what are the foreseen costs/dev time associated with the requirements */}
            {/* Sample pics of final design */}
            <br />
            <h6>Basic user flow:</h6>
            <figure>
              <a href={UserJourney} target="blank">
                <img
                  src={UserJourney}
                  className={styles.galleryImageLong}
                  alt="User journey flow diagram"
                />
              </a>
              <figcaption>User journey flow diagram</figcaption>
            </figure>
          </section>

          {/* Development stages */}
          <section className={styles.mainSection}>
            <h2>Development stages</h2>
            <br />
            <ol>
              <li>Approve screen designs and UI behavior</li>
              <li>Strategize application data flow and state management</li>
              <li>Create app views</li>
              <li>Create and test behavior and Shopify API calls</li>
              <li>
                Usability / end-user testing (prepare for launch & delivery)
              </li>
              <li>Final delivery</li>
            </ol>

            {/* In what order will I approach the steps/stages. Why?
        What is the projected timeline & justification */}
          </section>

          {/* Progress */}
          <section className={styles.mainSection}>
            <h2>Progress</h2>
            <p>Initial design stage is complete. Development is in progress</p>

            {/* <p>Currently: </p> */}
            {/* Where am I */}

            {/* Next steps */}

            {/* Challenges */}
          </section>
        </main>
      </div>
    </motion.div>
  )
}
